import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // LoadingPage
    loadingPage: false,
    //Número whats
    numeroWhats: null,
    //globalData - Dados que serão usados globalmente - Favoritos - etc..
    dataFavoritos: {
      //Imóveis que estão na lista de favoritos - relacionado ao cookie atual
      items: [],
      //Pastas / favoritos - relacionado ao cookie atual
      pastas: []
    },
    // Cookie do usuário atual
    FV_cookie_id: null,
    // SearchMain
    operacaoSearchHome: 'aluguel',
    // SectionFiltrosView | side-busca, etc
    isOpenFiltros: false,

    filtrosData: null,

    //QueryForm --> Formulários atualizarão esta string
    queryForm: {
      aluguel: true,
      palavraChave: "",
      type: "queryForm",
      venda: false
    },
    // Tipo de ordenação 
    queryOrder: 'valor-desc',
    //Págin atual
    queryPager: 1,
    //Exibir mapa (Sim|Não)
    showMapaImoveis: false,
    mapaInstance: null,
    mapaMarkers: [],
    mapaInfoWindow: [],
    // Se a requisição para listar imóveis usa uma queryString 
    // construida a partir dos dados da url
    isQueryRouter: false,
    queryRouter: {
      operacao: "",
      categoria: "",
      tipo: "",
      tipoIds: "",
      type: "queryRouter",
    },
    // Evento - área/Favoritos - Abrir pop-up Selecionar pasta
    popUpSelectPasta: {
      open: false,
      item: null
    },
    // Evento - área/Favoritos - Abrir pop-up Editar pasta
    popUpEditarPasta: {
      open: false,
      pasta: null,
      acao: null
    },
    // Evento - Abrir pop-up Compartilhar Imovel
    popUpCompartilhar: {
      open: false,
      item: null
    },
    // Evento - Abrir pop-up Compartilhar Pasta/Favoritos
    popUpCompartilharPasta: {
      open: false,
      item: null
    },
    // Propriedade do evento Router
    routeTo: null,
    // Imovel loaded
    imovelData: null
  },
  getters: {},
  mutations: {
    setDataFavoritos(state, v) {
      state.dataFavoritos = v;
    },
    setNumeroWhats(state, v) {
      state.numeroWhats = v;
    },
    setGlobalDataFavoritos(state, v) {
      state.globalData['favoritos'] = v;
    },
    setOperacaoSearchHome(state, v) {
      state.operacaoSearchHome = v;
    },
    setLoadingPage(state, v) {
      state.loadingPage = v;
    },
    setIsOpenFiltros(state, v) {
      state.isOpenFiltros = v;
    },
    setFiltrosData(state, v) {
      state.filtrosData = v;
    },
    setQueryForm(state, v) {
      state.queryForm = v;
    },
    setQueryRouter(state, v) {
      state.queryRouter = v;
    },
    setShowMapaImoveis(state, v) {
      state.showMapaImoveis = v;
    },
    setMapaInstance(state, v) {
      state.mapaInstance = v;
    },
    setMapaMarkers(state, v) {
      state.mapaMarkers = v;
    },
    setMapaInfoWindow(state, v) {
      state.mapaInfoWindow = v;
    },
    setQueryOrder(state, v) {
      state.queryOrder = v;
    },
    setQueryPager(state, v) {
      state.queryPager = v;
    },
    setIsQueryRouter(state, v) {
      state.isQueryRouter = v;
    },
    setfV_cookie_id(state, v) {
      state.FV_cookie_id = v;
    },
    setPopupSelectPasta(state, v) {
      state.popUpSelectPasta = v;
    },
    setPopUpEditarPasta(state, v) {
      state.popUpEditarPasta = v;
    },
    setPopUpCompartilhar(state, v) {
      state.popUpCompartilhar = v;
    },
    setPopUpCompartilharPasta(state, v) {
      state.popUpCompartilharPasta = v;
    },
    setRouteTo(state, v) {
      state.routeTo = v;
    },
    setImovelData(state, v) {
      state.imovelData = v;
    }
  },
  actions: {},
  modules: {}
})
