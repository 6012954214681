<template>
  <div class="wrap-header" id="header">
    <nav class="navbar navbar-coimca navbar-fixed-top">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            aria-expanded="false"
            aria-controls="navbar"
            @click.prevent="collapsed = !collapsed"
          >
            <span class="sr-only">Mudar navegação</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div id="navbar-btn-app" class="navbar-btn-favoritos">
            <router-link :to="{ name: 'Favoritos' }"
              >Favoritos <span></span
            ></router-link>
          </div>
        </div>
        <div id="navbar" :class="navbarClass">
          <ul class="nav navbar-nav">
            <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
            <li>
              <router-link :to="{ name: 'QuemSomos' }">Quem Somos</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Servicos' }">Serviços</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Contato' }">Contato</router-link>
            </li>
          </ul>
        </div>
        <!--/.nav-collapse -->
      </div>
    </nav>
    <header class="not-mobile">
      <div class="container">
        <div class="flex-h-itens">
          <div class="h-item item-w-1">
            <div class="inner">
              <div class="row">
                <div class="col-sm-7 col-md-5">
                  <a class="link-phone"
                    ><span class="icon-phone-outline"></span>54
                    <b>3223.4775</b></a
                  >
                </div>
                <div class="col-sm-5 col-md-7">
                  <ul class="nav nav-pills pull-right">
                    <li role="presentation">
                      <router-link :to="{ name: 'Home' }">Home</router-link>
                    </li>
                    <li role="presentation">
                      <router-link :to="{ name: 'QuemSomos' }"
                        >Quem Somos</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="h-item item-w-2">
            <div class="inner">
              <router-link class="link-logo" :to="{ name: 'Home' }">
                <img :src="logoImageUrl" class="img-responsive" />
              </router-link>
            </div>
          </div>
          <div class="h-item item-w-1">
            <div class="inner">
              <div class="row">
                <div class="col-sm-5 col-md-7">
                  <ul class="nav nav-pills pull-left">
                    <li role="presentation">
                      <router-link :to="{ name: 'Servicos' }"
                        >Serviços</router-link
                      >
                    </li>
                    <li role="presentation">
                      <router-link :to="{ name: 'Contato' }"
                        >Contato</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-sm-7 col-md-5">
                  <router-link
                    :to="{ name: 'Favoritos' }"
                    class="link-favoritos"
                    >Favoritos <span class="icon-favoritos"></span
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="mobile">
      <div class="container">
        <div class="flex-h-itens">
          <div class="h-item">
            <div class="inner">
              <router-link class="link-logo" :to="{ name: 'Home' }">
                <img :src="logoImageUrl" class="img-responsive" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import siteHelper from "@/mixins/siteHelper";
import { vueWindowSizeMixin } from "vue-window-size/option-api";
export default {
  name: "HeaderView",
  data() {
    return {
      collapsed: false,
    };
  },
  mixins: [siteHelper, vueWindowSizeMixin()],
  computed: {
    navbarClass() {
      return {
        collapse: !this.collapsed,
        "navbar-collapse": true,
        collapsed: this.collapsed,
      };
    },
    // Logo branco na home
    logoImageUrl() {
      return this.$route.name == "Home"
        ? `${this.base_url}assets/img/logo-coimica-branco.png`
        : `${this.base_url}assets/img/logo-coimica-v3.png`;
    },
  },
};
</script>
