<template>
  <div class="section-search-main">
    <div class="container">
      <form
        class="form-inline"
        name="formSearchMain"
        id="formSearchMain"
        @submit.prevent="submitFormSearch"
      >
        <div class="form-group search-main-group">
          <label class="sr-only" for="input-search-main"
            >Selecionar finalidade (Venda|Aluguel)</label
          >
          <div :class="inputGroupClass">
            <input
              type="text"
              class="form-control"
              id="input-search-main"
              v-model="q"
              :placeholder="placeholder"
            />
            <div
              class="input-group-addon addon-right"
              @click.prevent="submitFormSearch"
            >
              <span class="icon-search"></span>
            </div>
            <div class="left-options">
              <div
                :class="{ opt: true, active: opt == 'aluguel' ? true : false }"
              >
                <label
                  ><input
                    type="radio"
                    id="main-opt-aluquel"
                    v-model="opt"
                    value="aluguel"
                  />Locação</label
                >
              </div>
              <div
                :class="{ opt: true, active: opt == 'venda' ? true : false }"
              >
                <label
                  ><input
                    type="radio"
                    id="main-opt-venda"
                    v-model="opt"
                    value="venda"
                  />Venda</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import siteHelper from "@/mixins/siteHelper";
import { vueWindowSizeMixin } from "vue-window-size/option-api";

export default {
  name: "SearchMainv2",
  mixins: [siteHelper, vueWindowSizeMixin()],
  data() {
    return {
      collapsed: false,
      q: "",
      aluguel: true,
      venda: false,
      opt: "aluguel",
    };
  },
  mounted() {
    this.aluguel = true;
    this.venda = false;
    this.$store.commit("setOperacaoSearchHome", "aluguel");
  },
  watch: {
    opt(n) {
      this.$store.commit("setOperacaoSearchHome", n);
    },
  },

  computed: {
    ...mapState(["operacaoSearchHome"]),
    inputGroupClass() {
      return {
        "input-group input-group-lg": true,
        "input-group-mobile": this.$windowWidth <= 591 ? true : false,
      };
    },
    placeholder() {
      return this.$windowWidth >= 591
        ? "Digite código, cidade, bairro ou uma palavra-chave..."
        : "Pesquisar...";
    },
  },
  methods: {
    setOeracaoOption(v) {
      this.$store.commit("setOperacaoSearchHome", v);
    },
    submitFormSearch() {
      if (this.isBlank(this.q)) {
        return false;
      }
      let obj = {
        q: this.trimStr(this.q),
        aluguel: this.operacaoSearchHome == "aluguel" ? true : false,
        venda: this.operacaoSearchHome == "venda" ? true : false,
        fh: true, //Form - Home
      };
      //this.setCookieObj("site_search", obj);
      ///this.$store.commit("setQueryForm", obj);
      this.$router.push({
        path: `/imoveis/busca/`,
        query: { s: JSON.stringify(obj) },
      });
    },
  },
};
</script>
