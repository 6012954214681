<template>
  <div class="lista-categorias">
    <div class="container">
      <div class="row">
        <div class="col-xs-6"><h4>IMÓVEIS COMERCIAIS</h4></div>
        <div class="col-xs-6"><h4>IMÓVEIS RESIDENCIAIS</h4></div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <ul
            class="nav nav-pills nav-stacked"
            v-if="operacaoSearchHome == 'aluguel'"
          >
            <li
              role="presentation"
              v-for="item in tipos_comerciais_aluguel"
              :key="`${item.codigo}${item.id}`"
            >
              <a
                @click.prevent="
                  $router.push({ path: `/imoveis/locacoes/${item.route}` })
                "
                href="#"
                >{{ item.descricao }}</a
              >
            </li>
          </ul>
          <ul
            class="nav nav-pills nav-stacked"
            v-if="operacaoSearchHome == 'venda'"
          >
            <li
              role="presentation"
              v-for="item in tipos_comerciais_venda"
              :key="`${item.codigo}${item.id}`"
            >
              <a
                @click.prevent="
                  $router.push({ path: `/imoveis/vendas/${item.route}` })
                "
                href="#"
                >{{ item.descricao }}</a
              >
            </li>
          </ul>
        </div>
        <div class="col-xs-6">
          <ul
            class="nav nav-pills nav-stacked"
            v-if="operacaoSearchHome == 'aluguel'"
          >
            <li
              role="presentation"
              v-for="item in tipos_residenciais_aluguel"
              :key="`${item.codigo}${item.id}`"
              href="#"
            >
              <a
                @click.prevent="
                  $router.push({ path: `/imoveis/locacoes/${item.route}` })
                "
                href="#"
                >{{ item.descricao }}</a
              >
            </li>
          </ul>
          <ul
            class="nav nav-pills nav-stacked"
            v-if="operacaoSearchHome == 'venda'"
          >
            <li
              role="presentation"
              v-for="item in tipos_residenciais_venda"
              :key="`${item.codigo}${item.id}`"
            >
              <a
                @click.prevent="
                  $router.push({ path: `/imoveis/vendas/${item.route}` })
                "
                href="#"
                >{{ item.descricao }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import siteHelper from "@/mixins/siteHelper";
export default {
  name: "ListaCategorias",
  props: [
    "tipos_comerciais_aluguel",
    "tipos_comerciais_venda",
    "tipos_residenciais_aluguel",
    "tipos_residenciais_venda",
  ],
  mixins: [siteHelper],
  computed: {
    ...mapState(["operacaoSearchHome"]),
  },
};
</script>
