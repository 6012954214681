<template>
  <a
    v-if="this.numeroWhats !== null"
    class="fixed-whatsapp"
    :href="`https://api.whatsapp.com/send/?phone=${numeroWhatsOperacao.number}&text=${textWhats}&type=phone_number&app_absent=0`"
    target="_blank"
  >
    <span class="icon-whatsapp"></span>
  </a>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FixedWhatsapp",
  computed: {
    ...mapState(["numeroWhats", "operacaoSearchHome", "imovelData"]),
    numeroWhatsOperacao() {
      if (this.numeroWhats !== null) {
        if (this.operacaoSearchHome == "aluguel") {
          return this.numeroWhats["aluguel"];
        } else {
          return this.numeroWhats["venda"];
        }
      }
    },
    textWhats() {
      if (this.imovelData !== null && this.$route.name == "ImovelDetalhes") {
        return encodeURI(
          `Desejo mais informações sobre o imóvel ${this.imovelData.TIPOIMOVEL} - Cód. ${this.imovelData.CODIGOIMOVEL}/${this.imovelData.operacao}\r\n${this.imovelData.vue_link_permanente}`
        );
      } else {
        return "";
      }
    },
  },
};
</script>
