import Vue from 'vue'
import VeeValidate from "vee-validate";
import App from './App.vue'
import router from './router'
import store from './store'
//import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VeeValidate);
Vue.use(VueGtag, { config: { id: "G-8FNC126Q37" } });
//Vue.use(VueAnalytics, {id: 'UA-11507034-3'});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*
<div id="map" style="width:100%;height:405px;margin-top:20px;"></div>
              <script>

  // gestureHandling tag script e link necessária adicionada a head do site
  const map = L.map('map', {gestureHandling: true}).setView([-24.9592291, -53.5075280], 15);
  
  map.options.minZoom = 10; 
  map.setMaxBounds(-24.9592291, -53.5075280);

  const tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
  
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'

  }).addTo(map);
</script>

<script>
circle = L.circle([-24.9592291,-53.5075280], {
    color: 'red',
    fillColor: '#f03',
    fillOpacity: 0.5,
    radius: 500
}).addTo(map);
</script>
*/