<template>
  <div class="img-content">
    <div class="images-inner">
      <div class="owl-carousel owl-theme owl-imgs" :id="owlId">
        <div
          v-for="(img, index) in images"
          class="item"
          :key="img.url"
          :style="[
            index == 0
              ? { 'background-image': 'url(\'' + img.url + '\')' }
              : {},
            { '-webkit-background-size': 'cover' },
            { '-moz-background-size': 'cover' },
            { '-o-background-size': 'cover' },
            { 'background-size': 'cover' },
          ]"
          :data-url="img.url"
        >
          <a :href="linkImovel" target="_self" v-if="linkImovel !== null"></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import siteHelper from "@/mixins/siteHelper";
export default {
  name: "OwlImagensItem",
  props: ["images", "linkImovel"],
  mixins: [siteHelper],
  data() {
    return {
      owlId: "",
    };
  },
  created() {
    let $vm = this;
    $vm.owlId = $vm.uid();
  },
  mounted() {
    var owl = null;
    let $vm = this;
    setTimeout(() => {
      owl = $("#" + $vm.owlId + "").owlCarousel({
        loop: false,
        margin: 0,
        autoplay: false,
        nav: true,
        dots: false,
        items: 1,
        timeout: 500,
        smartSpeed: 300,
      });
      owl.on("changed.owl.carousel", function (property) {
        var current = property.item.index;
        var div = $(property.target)
          .find(".owl-item")
          .eq(current)
          .find(".item");
        div.css({ "background-image": "url('" + div.data("url") + "')" });
      });
    }, 300);
  },
  computed: {
    loadingClass() {
      return {
        "loading-imgs": true,
        "loading-hide": this.hideLoading,
      };
    },
  },
  methods: {},
};
</script>
