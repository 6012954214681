<template>
  <div class="hidden">
    <div
      id="tpl-modal-select-pasta"
      class="modal-pasta white-popup white-popup-block"
    >
      <div class="container">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th width="100%" colspan="2" class="text-center">
                Selecionar lista
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pasta in pastas" :key="pasta.created_at">
              <td width="10%" class="text-center">
                <input
                  v-model="checkedFolder"
                  @click="btnVisible = true"
                  type="checkbox"
                  :value="pasta.id"
                />
              </td>
              <td>{{ pasta.nome }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-xs-6" style="min-height: 38px"></div>
          <div class="col-xs-6 text-right" style="min-height: 38px">
            <template v-if="btnVisible">
              <button class="btn btn-cadastro" @click.prevent="salvar">
                {{ btnText }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import siteHelper from "@/mixins/siteHelper";
export default {
  name: "ModalSelectPasta",
  data() {
    return {
      btnText: "Salvar",
      btnVisible: false,
      checkedFolder: [],
    };
  },
  mixins: [siteHelper],
  watch: {
    isOpen(n, o) {
      if (n == true) {
        this.openPopupSelectPasta();
      } else {
        this.cancelar();
      }
    },
  },
  computed: {
    ...mapState(["dataFavoritos", "FV_cookie_id", "popUpSelectPasta"]),
    pastas() {
      return this.dataFavoritos.pastas;
    },
    items() {
      return this.dataFavoritos.items;
    },
    item() {
      return this.popUpSelectPasta.item;
    },
    isOpen() {
      return this.popUpSelectPasta.open;
    },
    idsPasastasUser() {
      var aux = [];
      $.each(this.pastas, function (i, e) {
        aux.push(e.id);
      });
      return aux;
    },
  },
  methods: {
    openPopupSelectPasta() {
      let vm = this;
      $.magnificPopup.open({
        items: {
          src: "#tpl-modal-select-pasta",
          type: "inline",
        },
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            vm.$store.commit("setPopupSelectPasta", {
              open: false,
              item: null,
            });
          },
        },
      });
      this.arrayFoldersItem();
    },
    salvar() {
      var $vm = this;
      $vm.btnText = "Salvando...";
      $.ajax({
        url: `${$vm.api_url}favoritos/update_item`,
        method: "POST",
        data: {
          item: $vm.item,
          pastasCheck: $vm.checkedFolder,
          cookie_id: $vm.FV_cookie_id,
          pastasUser: $vm.idsPasastasUser,
        },
      }).done((d) => {
        this.updateDataFavoritos();
        $vm.btnText = "OK!";
        setTimeout(function () {
          $vm.btnText = "Salvar";
        }, 2000);
      });
    },
    cancelar() {
      $.magnificPopup.close();
      this.$store.commit("setPopupSelectPasta", { open: false, item: null });
      return true;
    },
    updateDataFavoritos() {
      var $vm = this;
      $.get(`${this.api_url}g_data_json?cookie_id=${this.FV_cookie_id}`).then(
        (d) => {
          $vm.$store.commit("setDataFavoritos", d.favoritos);
          $vm.loadingAjax = false;
        }
      );
    },
    arrayFoldersItem: function () {
      var $vm = this;
      $.each(this.items, function (i, e) {
        if ($vm.item.codigo == e.codigo) {
          if (!$vm.checkedFolder.includes(e.pasta_id)) {
            $vm.checkedFolder.push(e.pasta_id);
          }
        }
      });
    },
  },
};
</script>
