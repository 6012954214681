<template>
  <div :class="{ 'imovel-wrap': true, 'imovel-wrap-bg': !addFavoritos }">
    <div class="col-w">
      <template v-if="item.TIPODESTAQUE != 0 && showRibbom == true">
        <div class="ribbon">
          <span :style="{ 'background-color': item.tipo_dest_cor }">{{
            item.tipo_dest_titulo
          }}</span>
        </div>
      </template>
      <owl-imagens-item
        :images="item.images"
        :itemId="item.id"
        :linkImovel="item.v_link_permanente"
      ></owl-imagens-item>
    </div>
    <div class="col-w">
      <div class="item-info">
        <h5>{{ item.TIPOIMOVEL }} CÓD.: {{ item.CODIGOIMOVEL }}</h5>
        <p class="bairro">{{ item.BAIRRO }}</p>
        <p class="valor">
          <template
            v-if="item.operacao == 'venda' && item.VALOR['venda'] !== ''"
          >
            R$ {{ item.VALOR["venda"] }}
          </template>
          <template
            v-if="item.operacao == 'aluguel' && item.VALOR['aluguel'] !== ''"
          >
            R$ {{ item.VALOR["aluguel"] }}
          </template>
          <template
            v-if="item.operacao == 'aluguel' && item.VALOR['aluguel'] == ''"
          >
            Consulte-nos
          </template>
          <template
            v-if="item.operacao == 'venda' && item.VALOR['venda'] == ''"
          >
            Consulte-nos
          </template>
        </p>
        <a :href="item.v_link_permanente"></a>
      </div>
    </div>
    <div class="col-w">
      <div class="options">
        <div class="options-left">
          <template
            v-if="
              item.QUANTIDADEGARAGEM != '0' &&
              isBlank(item.QUANTIDADEGARAGEM) == false
            "
          >
            <a
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              title="Número de garagens"
            >
              <span class="icon-cab"></span
              ><small>{{ item.QUANTIDADEGARAGEM }}</small>
            </a>
          </template>
          <template
            v-if="
              item.QUANTIDADEBANHEIRO != '0' &&
              isBlank(item.QUANTIDADEBANHEIRO) == false
            "
          >
            <a
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              title="Número de banheiros"
            >
              <span class="icon-bath"></span>
              <small>{{ item.QUANTIDADEBANHEIRO }}</small>
            </a>
          </template>
          <template
            v-if="
              item.QUANTIDADEDORMITORIO != '0' &&
              isBlank(item.QUANTIDADEDORMITORIO) == false
            "
          >
            <a
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              title=" Nº de quartos"
            >
              <span class="icon-bed"></span>
              <small>{{ item.QUANTIDADEDORMITORIO }}</small>
            </a>
          </template>
          <template
            v-if="
              item.AREATOTAL != '0' &&
              item.AREATOTAL != '0.00' &&
              isBlank(item.AREATOTAL) == false
            "
          >
            <a
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              title="Área m²"
            >
              <span class="icon-resize-horizontal-1"></span
              ><small>{{ item.AREATOTAL_NO_DECIMAL }} m²</small></a
            >
          </template>
        </div>
        <div class="options-right">
         <!--- <template
            v-if="
              isBlank(item.LONGITUDEMAPA) == false &&
              isBlank(item.LATITUDEMAPA) == false
            "
          >
            <a
              class="open-map"
              data-toggle="tooltip"
              data-placement="top"
              title="Visualizar no mapa"
              @click.prevent="
                openPopUpMapa(item.LATITUDEMAPA, item.LONGITUDEMAPA)
              "
            >
              <span class="icon-location"></span>
            </a>
          </template>-->
          <template v-if="addFavoritos">
            <list-item-favoritos
              :codigo="item.CODIGOIMOVEL"
              :nome="item.TIPOIMOVEL"
              :link="item.v_link_permanente"
              :operacao="item.operacao"
            ></list-item-favoritos>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OwlImagensItem from "@/components/imoveis/OwlImagensItem.vue";
import ListItemFavoritos from "@/components/favoritos/ListItemFavoritos.vue";
import siteHelper from "@/mixins/siteHelper";
export default {
  name: "ItemListaImovel",
  components: { OwlImagensItem, ListItemFavoritos },
  props: {
    item: { type: Object, default: null },
    showRibbom: { type: Boolean, default: false },
    addFavoritos: { type: Boolean, default: true },
  },
  mixins: [siteHelper],
  created() {},
  methods: {
    openPopUpMapa(lat, long) {
      $.magnificPopup.open({
        iframe: {
          markup:
            '<div class="mfp-iframe-scaler">' +
            '<div class="mfp-close"></div>' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            "</div>",
        },
        items: {
          src:
            "https://maps.google.com/maps?q=" +
            lat +
            "," +
            long +
            "&hl=es&z=15&amp;output=embed",
        },
        type: "iframe", // this is default type
        closeOnBgClick: false,
      });
    },
  },
};
</script>
