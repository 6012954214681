<template>
  <section class="page-home">
    <div :class="sessaoTopClass()" id="sc-top">
      <header-view></header-view>
      <owl-banner />
      <search-main />
      <lista-categorias
        :tipos_comerciais_aluguel="tipos_comerciais_aluguel"
        :tipos_comerciais_venda="tipos_comerciais_venda"
        :tipos_residenciais_aluguel="tipos_residenciais_aluguel"
        :tipos_residenciais_venda="tipos_residenciais_venda"
      ></lista-categorias>
    </div>
    <div class="sc-filtros">
      <section-filtros-view></section-filtros-view>
    </div>

    <div class="sc-imoveis" id="sc-imoveis">
      <div class="container-fluid">
        <div v-show="operacaoSearchHome == 'venda'">
          <owl-lista-imoveis
            :link="`/imoveis/vendas/destaques`"
            title="Destaques"
            sub-title="Visualizar todos os imóveis DESTAQUES"
            operacao="venda"
            :items="pageData.destaques_venda"
          ></owl-lista-imoveis>
          <owl-lista-imoveis
            :link="`/imoveis/vendas/comerciais`"
            title="Comerciais"
            sub-title="Visualizar todos os imóveis COMERCIAIS"
            operacao="venda"
            :items="pageData.comerciais_venda"
          ></owl-lista-imoveis>
          <owl-lista-imoveis
            :link="`/imoveis/vendas/residenciais`"
            title="Residenciais"
            sub-title="Visualizar todos os imóveis RESIDENCIAIS"
            operacao="venda"
            :items="pageData.residenciais_venda"
          ></owl-lista-imoveis>
          <owl-lista-imoveis
            :link="`/imoveis/vendas/industriais`"
            title="Industriais"
            sub-title="Visualizar todos os imóveis INDUSTRIAIS"
            operacao="venda"
            :items="pageData.industriais_venda"
          ></owl-lista-imoveis>
        </div>
        <div v-show="operacaoSearchHome == 'aluguel'">
          <owl-lista-imoveis
            :link="`/imoveis/locacoes/destaques`"
            title="Destaques"
            sub-title="Visualizar todos os imóveis DESTAQUES"
            operacao="aluguel"
            :items="pageData.destaques_aluguel"
          ></owl-lista-imoveis>
          <owl-lista-imoveis
            :link="`/imoveis/locacoes/comerciais`"
            title="Comerciais"
            sub-title="Visualizar todos os imóveis COMERCIAIS"
            operacao="aluguel"
            :items="pageData.comerciais_aluguel"
          ></owl-lista-imoveis>
          <owl-lista-imoveis
            :link="`/imoveis/locacoes/residenciais`"
            title="Residenciais"
            sub-title="Visualizar todos os imóveis RESIDENCIAIS"
            operacao="aluguel"
            :items="pageData.residenciais_aluguel"
          ></owl-lista-imoveis>
          <owl-lista-imoveis
            :link="`/imoveis/locacoes/industriais`"
            title="Industriais"
            sub-title="Visualizar todos os imóveis INDUSTRIAIS"
            operacao="aluguel"
            :items="pageData.industriais_aluguel"
          ></owl-lista-imoveis>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import siteHelper from "@/mixins/siteHelper";
import allTiposHome from "@/mixins/allTiposHome";

import HeaderView from "@/views/HeaderView.vue";
import OwlBanner from "@/components/home/OwlBanner.vue";
//import SearchMain from "@/components/home/SearchMain.vue";
import SearchMain from "@/components/home/SearchMain.vue";
import ListaCategorias from "@/components/home/ListaCategorias.vue";
import OwlListaImoveis from "@/components/home/OwlListaImoveis.vue";
import SectionFiltrosView from "@/views/SectionFiltrosView.vue";

export default {
  name: "HomeView",
  data() {
    return {
      bgListClass: ["bg-01", "bg-02", "bg-03", "bg-04"],
      bgKey: 0,
      pageData: {
        comerciais_venda: [],
        residenciais_venda: [],
        comerciais_aluguel: [],
        residenciais_aluguel: [],
        industriais_aluguel: [],
        industriais_venda: [],
        //tipos_comerciais_venda: [],
        //tipos_comerciais_aluguel: [],
        //tipos_residenciais_venda: [],
        //tipos_residenciais_aluguel: [],
        destaques_venda: [],
        destaques_aluguel: [],
      },
    };
  },
  components: {
    HeaderView,
    OwlBanner,
    SearchMain,
    ListaCategorias,
    OwlListaImoveis,
    SectionFiltrosView,
  },
  mixins: [siteHelper, allTiposHome],
  computed: {
    ...mapState(["operacaoSearchHome"]),
    tipos_residenciais_venda() {
      return this.ALL_TIPOS_HOME.filter((v) => {
        return v.tipo == "residencial" && v.operacao == "aluguel";
      });
    },
    tipos_residenciais_aluguel() {
      return this.ALL_TIPOS_HOME.filter((v) => {
        return v.tipo == "residencial" && v.operacao == "aluguel";
      });
    },
    tipos_comerciais_aluguel() {
      return this.ALL_TIPOS_HOME.filter((v) => {
        return v.tipo == "comercial" && v.operacao == "aluguel";
      });
    },
    tipos_comerciais_venda() {
      return this.ALL_TIPOS_HOME.filter((v) => {
        return v.tipo == "comercial" && v.operacao == "aluguel";
      });
    },
  },
  created() {
    this.bgKey =
      this.bgListClass[Math.floor(Math.random() * this.bgListClass.length)];
  },
  mounted() {
    this.$gtag.pageview(this.$route.fullPath);
    //this.$ga.page(this.$route.fullPath);
  },
  methods: {
    //gerar bg class aleatório
    sessaoTopClass() {
      let strClass = { "sc-top": true };
      strClass["" + this.bgKey + ""] = true;
      return strClass;
    },
    //Buscar dados da página - api
    getJson() {
      let $vm = this;
      $.get(this.api_url).then((d) => {
        $vm.$store.commit("setLoadingPage", false);
        $vm.pageData = d;
        setTimeout(function () {
          $vm.updateCrousel();
        }, 100);
      });
    },
    updateCrousel() {
      $(".owl-imoveis-wrap").each(function () {
        var id = $("> div", $(this)).attr("id");
        $("#" + id + "").owlCarousel({
          loop: false,
          margin: 0,
          autoplay: false,
          nav: true,
          navText: [
            "<span class='icon-angle-left'></span>",
            "<span class='icon-angle-right'></span>",
          ],
          dots: false,
          items: 4,
          timeout: 500,
          smartSpeed: 300,
          responsive: {
            1200: { items: 4, margin: 20 },
            900: { items: 3, margin: 20 },
            768: { items: 3, nav: false, margin: 20, dots: true },
            600: { items: 2, margin: 20, nav: false, dots: true },
            400: { items: 1, margin: 20, nav: false, dots: true },
            380: { items: 1, margin: 20, nav: false, dots: true },
            0: { items: 1, margin: 15, nav: false, dots: true },
          },
        });
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //vm.$store.commit("setLoadingPage", true);
      vm.getJson();
    });
  },
};
</script>
