<template>
  <div id="app">
    <loading-page></loading-page>
    <router-view></router-view>
    <rodape-view />
    <!-- componente com html pop-up ['pasta-favoritos'] -->
    <modal-select-pasta></modal-select-pasta>
    <!-- fixed - whatsapp -->
    <fixed-whatsapp></fixed-whatsapp>
  </div>
</template>
<script>
import { mapState } from "vuex";
import siteHelper from "@/mixins/siteHelper";
import LoadingPage from "@/components/LoadingPage.vue";
import RodapeView from "@/views/RodapeView.vue";
import FixedWhatsapp from "@/components/FixedWhatsapp.vue";
import ModalSelectPasta from "@/components/favoritos/ModalSelectPasta.vue";
export default {
  name: "App",
  components: {
    LoadingPage,
    RodapeView,
    FixedWhatsapp,
    ModalSelectPasta,
  },
  mixins: [siteHelper],
  metaInfo: {
    title: process.env.VUE_APP_META_TITLE,
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "pt_BR",
    },
    meta: [
      //{ charset: "utf-8" },
      {
        name: "description",
        content: process.env.VUE_APP_META_DESCRIPTION,
      },
    ],
  },
  computed: {
    ...mapState(["numeroWhats", "FV_cookie_id"]),
  },
  watch: {
    $route(to, from) {
      if (to.name !== "Favoritos" && to.name !== "FavoritosColecao") {
        this.getGlobalDataJson();
      }
      if (
        to.name !== "ImoveisLista" &&
        to.name !== "ImoveisLista1" &&
        to.name !== "ImoveisLista2" &&
        to.name !== "ImoveisLista3" &&
        to.name !== "ImoveisLista4"
      ) {
        this.$store.commit("setQueryRouter", null);
      }
      //mostrar loading page
      this.$store.commit("setLoadingPage", true);
    },
  },
  mounted() {
    //this.getGlobalDataJson();
  },
  methods: {
    getGlobalDataJson() {
      if (this.FV_cookie_id !== null) {
        return true;
      }
      let $vm = this;
      var FV_cookie_id = null;
      if (this.fv_getCookie("fv_user_id") == "") {
        FV_cookie_id = this.fv_uniqueID();
        this.fv_setCookie("fv_user_id", FV_cookie_id, 368);
      } else {
        FV_cookie_id = this.fv_getCookie("fv_user_id");
      }
      $vm.$store.commit("setfV_cookie_id", FV_cookie_id);
      $.get(`${this.api_url}g_data_json?cookie_id=${FV_cookie_id}`).then(
        (d) => {
          $vm.$store.commit("setDataFavoritos", d.favoritos);
          if ($vm.numeroWhats == null) {
            $vm.$store.commit("setNumeroWhats", d.numeroWhats);
          }
        }
      );
    },
  },
};
</script>
