// Uma cópia json dos da tabela tipos_home do BANCO
// Isto foi feito para facilitar a busca por categoria para não passar os parâmetros ides pela url
// URL: app-site/home/tipos
const allTiposHome = {
  data() {
    return {
      //http://localhost/coimca/app-site/home/tipos
      ALL_TIPOS_HOME: [
        { "id": "5", "codigo": "9", "tipo": "residencial", "operacao": "aluguel", "descricao": "Kitinet", "route": "kitinet", "rel": null },
        { "id": "12", "codigo": "80", "tipo": "comercial", "operacao": "aluguel", "descricao": "Pr\u00e9dio Independente", "route": "predio-independente", "rel": null },
        { "id": "3", "codigo": "3", "tipo": "residencial", "operacao": "aluguel", "descricao": "Apartamento", "route": "apartamento", "rel": null },
        { "id": "10", "codigo": "77", "tipo": "comercial", "operacao": "aluguel", "descricao": "Loja T\u00e9rrea", "route": "loja-terea", "rel": null },
        { "id": "9", "codigo": "76", "tipo": "comercial", "operacao": "aluguel", "descricao": "Sala A\u00e9rea", "route": "sala-aerea", "rel": null },
        { "id": "11", "codigo": "78", "tipo": "residencial", "operacao": "aluguel", "descricao": "Apartamento Mobiliado", "route": "apartamento-mobiliado", "rel": null },
        { "id": "2", "codigo": "1", "tipo": "residencial", "operacao": "aluguel", "descricao": "Casa Residencial", "route": "casa-residencial", "rel": null },
        { "id": "8", "codigo": "75", "tipo": "comercial", "operacao": "aluguel", "descricao": "Casa Comercial", "route": "casa-comercial", "rel": "75,104" },
        { "id": "4", "codigo": "5", "tipo": "comercial", "operacao": "aluguel", "descricao": "Terreno", "route": "terreno", "rel": null },
        { "id": "14", "codigo": "92", "tipo": "residencial", "operacao": "aluguel", "descricao": "Cobertura", "route": "cobertura", "rel": null },
        { "id": "6", "codigo": "12", "tipo": "comercial", "operacao": "aluguel", "descricao": "Pavilh\u00e3o", "route": "pavilhao", "rel": null },
        { "id": "15", "codigo": "97", "tipo": "residencial", "operacao": "aluguel", "descricao": "Ch\u00e1cara", "route": "chacara", "rel": null }]
    }
  },
  methods: {
    // Buscar relação de ids (Tipos de imóveis que podem estar relacionados)
    getTipoHomeIdsRel(route, operacao) {
      let r = "";
      this.ALL_TIPOS_HOME.forEach(e => {
        if (route == e.route) {
          if (e.rel) {
            r = e.rel;
          } else {
            r = e.codigo;
          }
        }
      });
      return r;
    },
    // Buscar relação de ids (Tipos de imóveis que podem estar relacionados)
    getTipoHomeIdsRelArray(route, operacao) {
      let rel = "";
      let v = [];
      this.ALL_TIPOS_HOME.forEach(e => {
        if (route == e.route) {
          if (e.rel) {
            rel = e.rel;
          } else {
            rel = e.codigo;
          }
        }
      });
      if (rel.includes(",")) {
        return rel.split(",");
      } else {
        v.push(rel);
      }
      return v;
    },
    getCategoriaTipoIds(c, o) {
      if (c == 'comerciais') {
        return ["80", "77", "76", "86", "75", "5", "14"];
      }
      if (c == 'residenciais') {
        return ["9", "3", "78", "1", "92", "97"];
      }
      if (c == 'industriais') {
        return ["12"];
      }
      /*
      if (c == 'comerciais' && o == 'venda') {
        return ["6", "10", "7", "13", "11", "3", "15", "9", "20", "23", "24", "29"];
      }
      if (c == 'residenciais' && o == 'venda') {
        return ["2", "8", "14", "1", "5", "12", "4", "15", "17", "21", "22", "26", "28"];
      }
      if (c == 'industriais' && o == 'venda') {
        return ["9", "20"];
      }*/
      return [];
    }
  }
}
export default allTiposHome;