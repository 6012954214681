//Funções que serão reaproveitadas em vários lugares
const siteHelper = {
     data(){
          return {
             base_url: process.env.BASE_URL,
             api_url: process.env.VUE_APP_API_URL,
             app_site_url: process.env.VUE_APP_APP_SITE_URL
          }
     },
     methods: {
        isBlank(str) {
            return !str || /^\s*$/.test(str);
        },
        uid() {
         return (performance.now().toString(36)+Math.random().toString(36)).replace(/\./g,"");
        },
        debounce(func, wait, immediate) {
         var timeout;
         return function executedFunction() {
           var context = this;
           var args = arguments;
              
           var later = function() {
             timeout = null;
             if (!immediate) func.apply(context, args);
           };
       
           var callNow = immediate && !timeout;
          
           clearTimeout(timeout);
       
           timeout = setTimeout(later, wait);
          
           if (callNow) func.apply(context, args);
         };

       },
       truncate(str, num) {
        if (str.length <= num) {
          return str;
        }
        return str.slice(0, num) + '...';
      },
      onlyNumber: function(event){
        if (!/\d/.test(event.key) && event.key !== '.' && event.key !== ',') return event.preventDefault();
      },
      serializeObj(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      },
      trimStr(str){
        return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
      },
      // gerar um id único para cookie
      fv_uniqueID(){
        function chr4(){
            return Math.random().toString(16).slice(-4);
        }
        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
     },
     // Obter cookie através do nome
     fv_getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    //Gravar cookie
    fv_setCookie(cname, cvalue, exdays) {
        var d = new Date();
        if(exdays==0){
          document.cookie = cname + "=" + cvalue + ";" +";path=/";
          return;
        }
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    setCookieObj(cname, cvalue, exdays){
      var v = JSON.stringify(cvalue);
      this.fv_setCookie(cname, v, 0);
    },
    getCookieObj(name){
      var v =this.fv_getCookie(name);
      if(this.isBlank(v)){
          return {};
      }
      return JSON.parse(v);
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    }
  }
}
export default siteHelper;