import { render, staticRenderFns } from "./SectionFiltrosView.vue?vue&type=template&id=122be145&scoped=true"
import script from "./SectionFiltrosView.vue?vue&type=script&lang=js"
export * from "./SectionFiltrosView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122be145",
  null
  
)

export default component.exports