var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-content"},[_c('div',{staticClass:"images-inner"},[_c('div',{staticClass:"owl-carousel owl-theme owl-imgs",attrs:{"id":_vm.owlId}},_vm._l((_vm.images),function(img,index){return _c('div',{key:img.url,staticClass:"item",style:([
          index == 0
            ? { 'background-image': 'url(\'' + img.url + '\')' }
            : {},
          { '-webkit-background-size': 'cover' },
          { '-moz-background-size': 'cover' },
          { '-o-background-size': 'cover' },
          { 'background-size': 'cover' } ]),attrs:{"data-url":img.url}},[(_vm.linkImovel !== null)?_c('a',{attrs:{"href":_vm.linkImovel,"target":"_self"}}):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }