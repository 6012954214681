var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'imovel-wrap': true, 'imovel-wrap-bg': !_vm.addFavoritos }},[_c('div',{staticClass:"col-w"},[(_vm.item.TIPODESTAQUE != 0 && _vm.showRibbom == true)?[_c('div',{staticClass:"ribbon"},[_c('span',{style:({ 'background-color': _vm.item.tipo_dest_cor })},[_vm._v(_vm._s(_vm.item.tipo_dest_titulo))])])]:_vm._e(),_c('owl-imagens-item',{attrs:{"images":_vm.item.images,"itemId":_vm.item.id,"linkImovel":_vm.item.v_link_permanente}})],2),_c('div',{staticClass:"col-w"},[_c('div',{staticClass:"item-info"},[_c('h5',[_vm._v(_vm._s(_vm.item.TIPOIMOVEL)+" CÓD.: "+_vm._s(_vm.item.CODIGOIMOVEL))]),_c('p',{staticClass:"bairro"},[_vm._v(_vm._s(_vm.item.BAIRRO))]),_c('p',{staticClass:"valor"},[(_vm.item.operacao == 'venda' && _vm.item.VALOR['venda'] !== '')?[_vm._v(" R$ "+_vm._s(_vm.item.VALOR["venda"])+" ")]:_vm._e(),(_vm.item.operacao == 'aluguel' && _vm.item.VALOR['aluguel'] !== '')?[_vm._v(" R$ "+_vm._s(_vm.item.VALOR["aluguel"])+" ")]:_vm._e(),(_vm.item.operacao == 'aluguel' && _vm.item.VALOR['aluguel'] == '')?[_vm._v(" Consulte-nos ")]:_vm._e(),(_vm.item.operacao == 'venda' && _vm.item.VALOR['venda'] == '')?[_vm._v(" Consulte-nos ")]:_vm._e()],2),_c('a',{attrs:{"href":_vm.item.v_link_permanente}})])]),_c('div',{staticClass:"col-w"},[_c('div',{staticClass:"options"},[_c('div',{staticClass:"options-left"},[(
            _vm.item.QUANTIDADEGARAGEM != '0' &&
            _vm.isBlank(_vm.item.QUANTIDADEGARAGEM) == false
          )?[_c('a',{attrs:{"href":"#","data-toggle":"tooltip","data-placement":"top","title":"Número de garagens"}},[_c('span',{staticClass:"icon-cab"}),_c('small',[_vm._v(_vm._s(_vm.item.QUANTIDADEGARAGEM))])])]:_vm._e(),(
            _vm.item.QUANTIDADEBANHEIRO != '0' &&
            _vm.isBlank(_vm.item.QUANTIDADEBANHEIRO) == false
          )?[_c('a',{attrs:{"href":"#","data-toggle":"tooltip","data-placement":"top","title":"Número de banheiros"}},[_c('span',{staticClass:"icon-bath"}),_c('small',[_vm._v(_vm._s(_vm.item.QUANTIDADEBANHEIRO))])])]:_vm._e(),(
            _vm.item.QUANTIDADEDORMITORIO != '0' &&
            _vm.isBlank(_vm.item.QUANTIDADEDORMITORIO) == false
          )?[_c('a',{attrs:{"href":"#","data-toggle":"tooltip","data-placement":"top","title":" Nº de quartos"}},[_c('span',{staticClass:"icon-bed"}),_c('small',[_vm._v(_vm._s(_vm.item.QUANTIDADEDORMITORIO))])])]:_vm._e(),(
            _vm.item.AREATOTAL != '0' &&
            _vm.item.AREATOTAL != '0.00' &&
            _vm.isBlank(_vm.item.AREATOTAL) == false
          )?[_c('a',{attrs:{"href":"#","data-toggle":"tooltip","data-placement":"top","title":"Área m²"}},[_c('span',{staticClass:"icon-resize-horizontal-1"}),_c('small',[_vm._v(_vm._s(_vm.item.AREATOTAL_NO_DECIMAL)+" m²")])])]:_vm._e()],2),_c('div',{staticClass:"options-right"},[(_vm.addFavoritos)?[_c('list-item-favoritos',{attrs:{"codigo":_vm.item.CODIGOIMOVEL,"nome":_vm.item.TIPOIMOVEL,"link":_vm.item.v_link_permanente,"operacao":_vm.item.operacao}})]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }