<template>
  <div class="section-owl-banner">
    <div class="container">
      <div class="owl-home">
        <div class="owl-carousel owl-theme" ref="owlHome" id="owlHome">
          <div class="item">
            <h2 class="home-title">
              <b>A sua imobiliária de confiança</b><br />
              Há <b>38</b> anos garantindo bons negócios
            </h2>
          </div>
          <div class="item">
            <div class="img"></div>
          </div>
          <!-- <div class="item">
            <div class="img-2"></div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OwlBanner",
  mounted() {
    $(this.$refs.owlHome).owlCarousel({
      loop: false,
      margin: 0,
      nav: false,
      items: 1,
      dots: false,
      autoplay: true,
      timeout: 8000,
      smartSpeed: 300,
    });
  },
};
</script>
