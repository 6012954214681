<template>
  <div class="row">
    <div class="col-xs-12">
      <ol class="breadcrumb breadcrumb-lista">
        <li>
          <a href="#" @click.prevent="$router.push({ path: `${link}` })">{{
            title
          }}</a>
        </li>
        <li>
          <a
            @click.prevent="$router.push({ path: `${link}` })"
            :href="`${link}`"
            >{{ subTitle }}</a
          >
        </li>
      </ol>
    </div>
    <div class="col-xs-12">
      <div class="owl-imoveis-wrap">
        <div class="owl-carousel owl-theme" :id="owlId">
          <div
            class="item"
            v-for="(item, index) in items"
            :key="`${index}-${item.CODIGOIMOVEL}-${operacao}`"
          >
            <item-imovel :item="item" :showRibbom="true"></item-imovel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import siteHelper from "@/mixins/siteHelper";
import ItemImovel from "@/components/imoveis/ItemImovel.vue";

export default {
  name: "OwlListaImoveis",
  data() {
    return {
      owlId: "",
    };
  },
  props: {
    title: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    items: { type: Array, default: [] },
    operacao: { type: String, default: "" },
    link: { type: String, default: "" },
  },
  mixins: [siteHelper],
  components: {
    ItemImovel,
  },
  created() {
    let $vm = this;
    $vm.owlId = $vm.uid();
  },
  mounted() {
    /*
    let $vm = this;
    setTimeout(function () {
      $("#" + $vm.owlId + "").owlCarousel({
        loop: false,
        margin: 0,
        autoplay: false,
        nav: true,
        navText: [
          "<span class='icon-angle-left'></span>",
          "<span class='icon-angle-right'></span>",
        ],
        dots: false,
        items: 4,
        timeout: 500,
        smartSpeed: 300,
        responsive: {
          1200: { items: 4, margin: 20 },
          900: { items: 3, margin: 20 },
          768: { items: 3, nav: false, margin: 20, dots: true },
          600: { items: 2, margin: 20, nav: false, dots: true },
          380: { items: 2, margin: 20, nav: false, dots: true },
          0: { items: 1, margin: 15, nav: false, dots: true },
        },
      });
    }, 300); */
  },
  methods: {},
};
</script>
