<template>
  <div class="footer" id="footer" v-if="mostrarRodape">
    <div class="footer-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-border-right">
            <div class="center-block block-1">
              <h5>Onde estamos</h5>
              <address>
                Rua do Guia Lopes, 886<br />
                Loja Térrea - Centro<br />
                Caxias do Sul / RS - CEP 95020-391
              </address>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-border-right col-border-2">
            <div class="center-block block-2">
              <h5>Atendimento Coimca</h5>
              <p class="phone">(54) 3223.4775</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-border-right col-border-2">
            <div class="center-block block-3">
              <h5>Área do cliente</h5>
              <ul class="list-unstyled footer-links">
                <li>
                  <a href="https://portal.viaimob.com.br/133/" target="_blank"
                    >2ª via do boleto - Opção 1</a
                  >
                </li>
                <li>
                  <a href="https://portal.viaimob.com.br/223/" target="_blank"
                    >2ª via do boleto - Opção 2</a
                  >
                </li>
                <li>
                  <a href="https://portal.viaimob.com.br/133/" target="_blank"
                    >Demonstrativo do Proprietário - Opção 1</a
                  >
                </li>
                <li>
                  <a href="https://portal.viaimob.com.br/223/" target="_blank"
                    >Demonstrativo do Proprietário - Opção 2</a
                  >
                </li>
              </ul>
              <!--<p class="atendentes"><a>Atendentes Online</a></p>-->
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <ul class="list-unstyled list-inline list-social">
              <li>
                <a href="https://www.facebook.com/coimca/" target="_blank"
                  ><span class="icon-facebook"></span
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/imobiliariacoimca/"
                  target="_blank"
                  ><span class="icon-instagram"></span
                ></a>
              </li>
              <li>
                <a
                  v-if="numeroWhats"
                  :href="`https://wa.me/${numeroWhatsOperacao.number}`"
                  target="_blank"
                  ><span class="icon-whatsapp"></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size/option-api";
import siteHelper from "@/mixins/siteHelper";
export default {
  name: "RodapeView",
  mixins: [vueWindowSizeMixin(), siteHelper],
  data() {
    return {
      // Lista de rotas em que não será mostrado o rodapé.
      // Para carregamento melhorado da paginação onScroll [ lista de imóveis]
      ignoreRodapeList: [
        "ImoveisLista4",
        "ImoveisLista3",
        "ImoveisLista2",
        "ImoveisLista1",
        "ImoveisLista",
      ],
    };
  },
  computed: {
    ...mapState(["numeroWhats", "operacaoSearchHome", "imovelData"]),
    numeroWhatsOperacao() {
      if (this.numeroWhats !== null) {
        if (this.operacaoSearchHome == "aluguel") {
          return this.numeroWhats["aluguel"];
        } else {
          return this.numeroWhats["venda"];
        }
      }
    },
    mostrarRodape() {
      if (
        this.$windowWidth <= 992 &&
        this.ignoreRodapeList.includes(this.$route.name)
      ) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    /*if (this.fv_getCookie("popup") !== "ok") {
      this.fv_setCookie("popup", "ok", 0);
      $.magnificPopup.open({
        items: {
          src: "https://www.coimca.com.br/pop-up-2.png",
          type: "image",
        },
        closeOnBgClick: true,
      });
    }*/
  },
  //let k = Math.floor(Math.random() * this.numeros_whats.length);
  //this.whats = this.numeros_whats[k];
};
</script>
