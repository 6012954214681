<template>
  <div :class="wrapClass">
    <button class="btn btn-toolbar" @click.prevent="toggleWrap">
      Filtros Avançados
      <span class="icon-angle-right" v-if="!isOpenFiltros"></span>
      <span class="icon-angle-down" v-else></span>
    </button>
    <div class="wrap-inner">
      <div class="container">
        <filtros :isHomePage="true"></filtros>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import("@/components/filtros/Filtros.vue");
export default {
  name: "SectionFiltrosView",
  components: { Filtros: () => import("@/components/filtros/Filtros.vue") },
  computed: {
    ...mapState(["operacaoSearchHome", "isOpenFiltros"]),
    wrapClass() {
      return {
        "wrap-filtros": true,
        "wrap-filtros-open": this.isOpenFiltros,
      };
    },
  },
  methods: {
    toggleWrap() {
      this.$store.commit("setIsOpenFiltros", !this.isOpenFiltros);
    },
  },
};
</script>
<style scoped></style>
