import Vue from 'vue'
import store from './../store'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import HomeView from '@/views/HomeView.vue';

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView
    //component: () => ({
    //  component: import('@/views/HomeView.vue'),
    //})
  },
  {
    path: "/quem-somos",
    name: "QuemSomos",
    component: () => ({
      component: import('@/views/QuemSomosView.vue'),
    })
  },
  {
    path: "/servicos",
    name: "Servicos",
    component: () => ({
      component: import('@/views/ServicosView.vue'),
    })
  },
  {
    path: "/favoritos/colecao/*/:id",
    name: "FavoritosColecao",
    component: () => ({
      component: import('@/views/FavoritosView.vue'),
    }),
  },
  {
    path: "/favoritos",
    name: "Favoritos",
    component:  () => ({
      component: import('@/views/FavoritosView.vue'),
    }),
  },
  {
    path: "/contato",
    name: "Contato",
    component:  () => ({
      component: import('@/views/ContatoView.vue'),
    }),
  },
  {
    path:  '/imoveis/busca/',
    name: "ImoveisLista1",
    component:  () => ({
      component: import('@/views/ImoveisView.vue'),
    }),
    meta: { tipoBusca:'form' } 
  },

  // Ex: /imoveis/locacoes/destaques
  // Links: A partir do carrosel de categorias - Home
  {
    path:  '/imoveis/locacoes/destaques',
    alias: [
      '/imoveis/locacoes/comerciais',
      '/imoveis/locacoes/residenciais',
      '/imoveis/locacoes/industriais'
    ],
    name: "ImoveisLista2",
    component:() =>({
      component: import('@/views/ImoveisView.vue'),
    }),
    meta: { tipoBusca:'categoria', operacao:"aluguel" } 
  },
  {
    path:  '/imoveis/vendas/destaques',
    alias: [
      '/imoveis/vendas/comerciais',
      '/imoveis/vendas/residenciais',
      '/imoveis/vendas/industriais'
    ],
    name: "ImoveisLista3",
    component:() => ({
      component: import('@/views/ImoveisView.vue'),
    }),
    meta: { tipoBusca:'categoria', operacao:"venda" } 
  },
  // Ex: /imoveis/locacoes/predio-independente
  // Links: A partir das colunas com lista de categorias - (home abaixo do campo de busca)
  {
    path:  '/imoveis/vendas/:tipo',
    name: "ImoveisLista4",
    component:() => ({
      component: import('@/views/ImoveisView.vue'),
    }),
    meta: { tipoBusca:'finalidade-tipo', operacao:"venda" } 
  },
  {
    path:  '/imoveis/locacoes/:tipo',
    name: "ImoveisLista5",
    component:() => ({
      component: import('@/views/ImoveisView.vue'),
    }),
    meta: { tipoBusca:'finalidade-tipo', operacao:"aluguel" } 
  },
  // Link permanente do imóvel
  {
    path:  '/imovel/:operacao/*/*/*/:codigo/',
    name: "ImovelDetalhes",
    component:() => ({
      component: import('@/views/ImovelDetalhes.vue'),
    }),
    meta: { } 
  },
  //Ex: cadastro/locacao-pessoa-fisica
  {
    path:  '/cadastro/:tipo',
    name: "CadastroView",
    component:() => ({
      component: import('@/views/CadastroView.vue'),
    }),
    meta: { } 
  },
  {
    path: "*",
    component:  () => ({
    component: import('@/views/NotfoundView.vue'),
    }),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  
  store.commit("setLoadingPage", true);
  next();
})
export default router
