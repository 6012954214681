<template>
  <a
    href="#"
    v-bind:class="{
      'add-favoritos': true,
      active: checkActive(),
      'item-text': text,
    }"
    @click.prevent="addTo()"
    data-toggle="tooltip"
    data-placement="top"
    :data-original-title="itemToolptipText"
  >
    <span :class="iconClass"></span> 
    <template v-if="text">
        <small>{{ itemText }}</small>
    </template>
  </a>
</template>
<script>
import { mapState } from "vuex";
import siteHelper from "@/mixins/siteHelper";
export default {
  name: "ListItemFavoritos",
  components: {},
  props: {
    codigo: { type: String, required: false, default: "" },
    nome: { type: String, required: false, default: "" },
    link: { type: String, required: false, default: "" },
    operacao: { type: String, required: false, default: "" },
    text: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      loadingAjax: false,
    };
  },
  mixins: [siteHelper],
  computed: {
    ...mapState(["dataFavoritos", "FV_cookie_id"]),
    pastas() {
      return this.dataFavoritos.pastas;
    },
    items() {
      return this.dataFavoritos.items;
    },
    iconClass() {
      return {
        "icon-heart": this.checkActive() && !this.loadingAjax,
        "icon-heart-empty": !this.checkActive() && !this.loadingAjax,
        "animate-spin icon-spin3": this.loadingAjax,
      };
    },
    itemToolptipText() {
      return !this.checkActive()
        ? "Adicionar aos favoritos!"
        : "Remover dos favoritos!";
    },
    itemText: function () {
      if (this.text) {
        return " FAVORITAR";
      } else {
        return "";
      }
    },
  },
  created() {},
  methods: {
    addTo() {
      // Existe apenas uma pasta
      if (this.pastas.length == 1) {
        var pastasUser = [this.pastas[0].id];
        var pastasCheck = [];
        if (!this.checkActive()) {
          pastasCheck.push(this.pastas[0].id);
        }
        this.updateItem(
          {
            codigo: this.codigo,
            nome: this.nome,
            link: this.link,
            operacao: this.operacao,
          },
          pastasUser,
          pastasCheck
        );
      }
      //Existe mais de uma pasta - Abre o pop up para selecionar pasta
      if (this.pastas.length > 1) {
        this.$store.commit("setPopupSelectPasta", {
          open: true,
          item: {
            codigo: this.codigo,
            nome: this.nome,
            link: this.link,
            operacao: this.operacao,
          },
        });
      }
    },
    checkActive() {
      var $vm = this;
      var r = false;
      $.each($vm.items, function (i, e) {
        if (e.codigo == $vm.codigo) {
          r = true;
        }
      });
      return r;
    },
    updateItem(item, pastasUser, pastasCheck) {
      var $vm = this;
      $vm.loadingAjax = true;
      $.ajax({
        url: $vm.api_url + "favoritos/update_item",
        method: "POST",
        data: {
          item: item,
          pastasCheck: pastasCheck,
          pastasUser: pastasUser,
          cookie_id: $vm.FV_cookie_id,
        },
      }).done(function (d) {
        $vm.updateDataFavoritos();
      });
    },
    updateDataFavoritos() {
      var $vm = this;
      $.get(`${this.api_url}g_data_json?cookie_id=${this.FV_cookie_id}`).then(
        (d) => {
          $vm.$store.commit("setDataFavoritos", d.favoritos);
          $vm.loadingAjax = false;
        }
      );
    },
  },
};
</script>
