<template>
  <section :class="sectionClass">
    <div><span class="animate-spin icon-spin3"></span></div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "LoadingPage",
  computed: {
    ...mapState(["loadingPage"]),
    sectionClass() {
      return {
        "loading-page": true,
        "loading-page-visible": this.loadingPage,
      };
    },
  },
};
</script>
